import { I18n } from '@lingui/core';
import { AxiosResponse } from 'axios';
import formatISO from 'date-fns/formatISO';

import { client as apiClient } from 'libs/api';
import {
    DocumentType,
    DownloadClosingDocumentToDocCentreOptions,
} from 'types/documents';
import { isEmpty } from 'utils';

import type { ClosingDocumentLetterTemplateType } from 'types/closing-documents';

export const toDateNoTime = (date: Date | string) => {
    if (isEmpty(date)) {
        return undefined;
    }

    const isDate = date instanceof Date;

    const yyyyMmDd = isDate
        ? formatISO(date as Date, { representation: 'date' })
        : String(date).substring(0, 10);

    return `${yyyyMmDd}T00:00:00Z`;
};

export const handleServicingBlobError = async (i18n: I18n, error: any) => {
    // We are expecting a blob response, when that fails we need to handle Servicing's error object, which uses parent / child
    const blobResponse = error?.response;
    const response = JSON.parse((await blobResponse?.data?.text()) || '{}');
    const invalidFields = response?.child?.parameters || response?.parameters;

    const missingFieldsError = `${i18n._(`loanAgreement.missingFields`)} ${(
        invalidFields || []
    ).join(', ')}`;

    const otherError = `Error: ${
        response?.description || i18n._(`browserPDF.error`)
    }`;

    return { invalidFields, missingFieldsError, otherError };
};

export const getClosingDocModalTitle = (
    i18n: I18n,
    documentType: DocumentType,
    template: ClosingDocumentLetterTemplateType
) => {
    const titleMap = {
        HELOC_LOAN_AGREEMENT: 'helocLoanAgreement.title.languaged',
        LOAN_AGREEMENT_EXTERNAL: 'loanAgreement.title.languaged',
        BRIDGE_LOAN_APPROVAL_LETTER: 'bridgeLoanLetter.title.languaged',
        PRE_APPROVAL_LETTER: 'preApproval.title.languaged',
        FINAL_LETTER: 'finalLetter.title.languaged',
        UNSIGNED_COMMITMENT_LETTER: 'unsignedCommitmentLetter.title.languaged',
        NOTICE_OF_CREDIT_CHARGES: 'noticeOfCreditCharges.title.languaged',
    };

    const title = titleMap[documentType as keyof typeof titleMap] || '';

    return i18n._(title, {
        language: template && i18n._(template),
    });
};

export const getClosingDocPDFOriginalFilename = (
    documentType: DocumentType,
    template: ClosingDocumentLetterTemplateType
) => {
    const originalFilenameMap = {
        HELOC_LOAN_AGREEMENT: `Heloc Loan Agreement ${template}`,
        LOAN_AGREEMENT_EXTERNAL: `Loan Agreement ${template}`,
        BRIDGE_LOAN_APPROVAL_LETTER: `Bridge Loan Letter ${template}`,
        PRE_APPROVAL_LETTER: `Pre-Approval Letter ${template}`,
        FINAL_LETTER: `Final Letter ${template}`,
        UNSIGNED_COMMITMENT_LETTER: `Unsigned Commitment Letter ${template}`,
        NOTICE_OF_CREDIT_CHARGES: `Notice of Credit Charges Letter ${template}`,
    };

    return (
        originalFilenameMap[documentType as keyof typeof originalFilenameMap] ||
        ''
    );
};

export const getClosingDocPDFFile = async (
    applicationId: number,
    documentType: DocumentType,
    template: ClosingDocumentLetterTemplateType
) => {
    let response: AxiosResponse<Blob>;

    switch (documentType) {
        case 'HELOC_LOAN_AGREEMENT':
            response = await apiClient.getHelocLoanAgreementPDF(
                applicationId,
                template
            );

            return response.data;

        case 'LOAN_AGREEMENT_EXTERNAL':
            response = await apiClient.getLoanAgreementPDF(
                applicationId,
                template
            );

            return response.data;

        case 'BRIDGE_LOAN_APPROVAL_LETTER':
            response = await apiClient.getBridgeLoanLetterPDF(
                applicationId,
                template
            );

            return response.data;

        case 'FINAL_LETTER':
        case 'PRE_APPROVAL_LETTER':
            response = await apiClient.getClosingDocumentLetterPDF(
                applicationId,
                template
            );

            return response.data;

        case 'UNSIGNED_COMMITMENT_LETTER':
            response = await apiClient.getUnsignedCommitmentLetterPDF(
                applicationId,
                template
            );

            return response.data;

        case 'NOTICE_OF_CREDIT_CHARGES':
            response = await apiClient.getNoticeOfCreditChargesPDF(
                applicationId,
                template
            );

            return response.data;

        default:
            return Promise.reject();
    }
};

export const downloadClosingDocPDFFile = async (
    applicationId: number,
    documentType: DocumentType,
    template: ClosingDocumentLetterTemplateType,
    options?: {
        onSuccess?: (filePreviewUrl: string) => void;
        onError?: () => void;
    }
) => {
    try {
        const file = await getClosingDocPDFFile(
            applicationId,
            documentType,
            template
        );

        const filePreviewUrl = URL.createObjectURL(
            new Blob([file], { type: 'application/pdf' })
        );

        window.open(filePreviewUrl, '_blank');

        options?.onSuccess?.(filePreviewUrl);
    } catch (error) {
        console.log(`[Error while downloading closind doc pdf file]: ${error}`);

        options?.onError?.();
    }
};

export const downloadClosingDocToDocumentCentre = async (
    options: DownloadClosingDocumentToDocCentreOptions,
    formData: FormData
) => {
    const { applicationId, applicantsId, documentType } = options;

    /* Remove after BE issue fixed: We're chaining those promises because
       we have a deep BE issue righ now when we run all of them at the same time */
    for (let i = 0; i < applicantsId.length; i++) {
        const applicantId = applicantsId[i];

        await apiClient.downloadClosingDocumentToDocumentCenter(
            { applicationId, applicantId, documentType },
            formData
        );
    }
};

export const onDownloadClosingDocToDocumentCentre = async (
    originalFileName: string,
    getPDFFile: () => Promise<Blob>,
    downloadClosingDocToDocumentCentre: (formData: FormData) => Promise<void>
) => {
    const file = await getPDFFile();

    // Create a form data object to send to the API
    const formData = new FormData();

    formData.append('file', file);

    formData.append(
        'json',
        JSON.stringify({
            contentType: 'application/pdf',
            originalFileName,
        })
    );

    await downloadClosingDocToDocumentCentre(formData);
};
