import { useTenant } from '@nestoca/multi-tenant';
import { Flex, Skeleton } from '@nestoca/ui';
import cn from 'classnames';

import { useI18n } from 'providers/i18n/use-i18n';

import { PdfPreviewHeader } from './pdf-preview-header';
import styles from './pdf-preview.module.scss';

export const PdfPreviewSkeleton = () => {
    const { i18n } = useI18n();
    const { displayName, logo } = useTenant();

    return (
        <Flex
            className={cn(
                styles['pdf-preview'],
                styles['pdf-preview--skeleton']
            )}
            direction="column"
        >
            <PdfPreviewHeader />
            <Flex className={styles.content} direction="column">
                <img
                    className={styles.logo}
                    alt={`${displayName} logo`}
                    src={logo?.primary[i18n.locale]?.url}
                    width={150}
                />
                <Skeleton
                    className={styles['top-container']}
                    animate
                    height={296}
                />
                <Flex direction="column" gap={4}>
                    <Skeleton animate count={3} height={126} />
                </Flex>
            </Flex>
        </Flex>
    );
};
