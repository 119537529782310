import { useEffect, useState } from 'react';

import { Trans } from '@lingui/react';
import { Banner, Button, Center, Flex, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { BsExclamationDiamondFill } from 'react-icons/bs';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { NESTO_SUPPORT_LINK } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    applicationProblemsSidebar,
    currentApplicationIdState,
} from 'store/applications';
import { handleServicingBlobError } from 'utils/closing-documents';

import { PdfPreviewHeader } from './pdf-preview-header';
import styles from './pdf-preview.module.scss';

type Props = {
    error?: Error;
};

export const PdfPreviewError = ({ error }: Props) => {
    const [hasMissingFields, setHasMissingFields] = useState(false);

    const applicationId = useRecoilValue(currentApplicationIdState);

    const setProblemsSidebarStatus = useSetRecoilState(
        applicationProblemsSidebar
    );

    const { i18n } = useI18n();

    const { push } = useRouter();

    // Open the application problems sidebar and redirect to the application page
    const handleFixIssuesRedirect = () => {
        setProblemsSidebarStatus({
            ...applicationProblemsSidebar,
            isSidebarOpen: true,
            sidebarView: 'missingFields',
        });

        push(`/applications/${applicationId}`);
    };

    useEffect(() => {
        (async () => {
            try {
                const pdfError = await handleServicingBlobError(i18n, error);

                setHasMissingFields(!!pdfError.missingFieldsError);
            } catch (error) {
                console.error(
                    `[Error while handling servicing blob errors]: ${error}`
                );
            }
        })();
    }, [i18n, error]);

    return (
        <Flex
            className={cn(styles['pdf-preview'], styles['pdf-preview--error'])}
            direction="column"
            gap={5}
        >
            <Banner
                className={styles['warning-banner']}
                visible={hasMissingFields}
                variant="warning"
            >
                <Flex align="center" gap={5}>
                    <Typography size={0} height={2}>
                        <Trans
                            id="pdfPreview.missingFields"
                            components={{
                                0: (
                                    <a
                                        className={styles['nesto-support-link']}
                                        target="_blank"
                                        rel="noreferrer"
                                        href={NESTO_SUPPORT_LINK}
                                    />
                                ),
                            }}
                        />
                    </Typography>
                    <Button
                        className={styles['fix-issues-link']}
                        size="medium"
                        variant="link"
                        onClick={handleFixIssuesRedirect}
                    >
                        {i18n._('fixIssues')}
                    </Button>
                </Flex>
            </Banner>
            <Flex className={styles.preview} direction="column">
                <PdfPreviewHeader />
                <Center className={styles.content}>
                    <Flex align="center" gap={3}>
                        <BsExclamationDiamondFill color="var(--color-status-error)" />
                        <Typography weight={6}>
                            {i18n._('browserPDF.error')}
                        </Typography>
                    </Flex>
                </Center>
            </Flex>
        </Flex>
    );
};
