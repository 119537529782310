import { Flex } from '@nestoca/ui';
import { AiFillPrinter } from 'react-icons/ai';
import { BsList, BsThreeDotsVertical } from 'react-icons/bs';
import { IoMdDownload } from 'react-icons/io';

import styles from './pdf-preview.module.scss';

export const PdfPreviewHeader = () => {
    return (
        <Flex
            className={styles.header}
            align="center"
            justify="between"
            gap={5}
        >
            <BsList size={20} />
            <Flex align="center" gap={5}>
                <IoMdDownload size={20} />
                <AiFillPrinter size={20} />
                <BsThreeDotsVertical size={18} />
            </Flex>
        </Flex>
    );
};
